import { Card, CardProps, Img, Text } from "@xcorejs/ui";
import { FC } from "react";
import styled from "styled-components";
import { useLayout } from "xcore";
import { Gobo } from "xcore/types";

export type GoboCardProps = {
  gobo: Gobo;
  active?: boolean;
  large?: boolean;
} & CardProps;

const GoboCard: FC<GoboCardProps> = ({ gobo, active, large, ...props }) => {
  const { file, stringify } = useLayout();

  return (
    <GoboCardStyle
      variant="outline"
      body={(
        <>
          {large ? (
            <Img
              src={file(gobo.values.image, "96x96")}
              srcSet={`${file(gobo.values.image, "96x96")} 1x, ${file(gobo.values.image, "96x96@2x")} 2x`}
              alt={stringify(gobo.values.name)}
              maxWidth="100%"
              loading="lazy"
            />
          ) : (
            <Img
              src={file(gobo.values.image, "48x48")}
              srcSet={`${file(gobo.values.image, "48x48")} 1x, ${file(gobo.values.image, "48x48@2x")} 2x`}
              alt={stringify(gobo.values.name)}
              maxWidth="100%"
              loading="lazy"
            />
          )}

          {stringify(gobo.values.name) && (
            <Text
              mt="1.5rem"
              textAlign="center"
              fontSize="1.4rem"
              lineHeight="2.1rem"
              fontWeight={600}
              color={active ? "#1e1e1e" : "gray"}
            >
              {stringify(gobo.values.name)}
            </Text>
          )}
        </>
      )}
      _body={{ flexDirection: "column", alignItems: "center", pb: "1.5rem" }}
      transition="border 300ms"
      width="100%"
      borderColor={active ? "robe" : "#dee1e5"}
      _hover={active ? { border: "2px solid robe" } : { border: "2px solid rgba(69, 86, 99, 0.75)" }}
      {...props}
    />
  )
  ;
};

export default GoboCard;

const GoboCardStyle = styled(Card)<CardProps>`
  @media screen and (max-width: 767px) {
    border-color: #dee1e5 !important;
    
    span {
      color: gray !important;
    }
  }
`;
