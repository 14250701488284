import { Link, only, useContent, usePageContent, useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Flex, Icon, Text } from "@xcorejs/ui";
import InquiryButton from "components/InquiryButton";
import Subheader from "components/Layout/Subheader";
import ArrowDown from "components/icons/8/arrow-down.svg";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Product, ProductDetailPage } from "xcore/types";
import ProductSubHeaderContent from "./ProductSubheaderContent";
import useProductDetailPageStore from "./useProductDetailPageStore";

export const ProductDetailSubheader = () => {
  const { query } = useRouter();
  const [product, { stringify }] = usePageContent<Product>();
  const [detailPage] = useContent<[ProductDetailPage]>();

  const backToLabels = useMemo(() => ({
    product: detailPage?.values.backToProduct,
    news: detailPage?.values.backToNews,
    innovation: detailPage?.values.backToInnovation,
    _: detailPage?.values.backToPage
  }), [
    detailPage?.values.backToInnovation,
    detailPage?.values.backToNews,
    detailPage?.values.backToPage,
    detailPage?.values.backToProduct
  ]);

  const backTo = only(query.backto) ?? null;
  const { data } = useXcoreQuery(cms => backTo && cms.content.single(backTo));
  const { showSubheader } = useProductDetailPageStore();

  if (!showSubheader) {
    return null;
  }

  return (
    <Subheader
      leftContent={query.backto && data ? (
        <Link content={data.content}>
          <Flex alignItems="center" title={stringify(data.content.title)}>
            <Icon
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="16px"
              p="8px"
              width="32px"
              height="32px"
              textAlign="center"
              bg="primary"
              fill="white"
              style={{ transform: "rotate(90deg)" }}
              mr="10px"
            >
              <ArrowDown />
            </Icon>
            <Text color="#c7cace" whiteSpace="nowrap">
              {stringify(backToLabels[data.content.type] ?? backToLabels._)}
            </Text>
          </Flex>
        </Link>
      ) : (
        <Flex alignItems="center">
          <Text variant="strong" color="#fff">{stringify(product.values.name)}</Text>
        </Flex>
      )}
      middleContent={<ProductSubHeaderContent />}
      rightContent={(
        <Flex justifyContent="flex-end">
          <InquiryButton product={product} />
        </Flex>
      )}
    />
  );
};
