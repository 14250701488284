import React, { FC, ReactNode } from "react";
import { BoxProps, Icon, Tag } from "@xcorejs/ui";

import IPCertifiedSvg from "components/icons/iseries-drop.svg";

type TagProps = {
  variant: "gray" | "new" | "ipCertified";
  centered?: boolean;
  children?: ReactNode;
} & BoxProps;
const ProductTag: FC<TagProps> = ({ variant, children, centered, ...props }) => {
  const isNew = variant === "new";
  const isIpCertified = variant === "ipCertified";

  return (
    <Tag
      backgroundColor={isNew ? "primary" : isIpCertified ? "ipCertified" : null}
      borderColor={isNew ? "primary" : isIpCertified ? "ipCertified" : null}
      display={centered && "inline"}
      textAlign="center"
      {...props}
    >
      {variant === "ipCertified" && <Icon svg={IPCertifiedSvg} fill="white" ml="-.5rem" mr=".5rem" />}
      {children}
    </Tag>
  );
};

export default ProductTag;
