import { useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { AspectRatio, Box, Card, Container, Img, Link as UILink } from "@xcorejs/ui";
import RobeCarousel from "design-system/robe/Carousel";
import { Product, ProductDetailPage } from "xcore/types";

import { useGallery } from "./data";
import useProductDetailPageStore from "./useProductDetailPageStore";
import { PropsWithChildren, useMemo } from "react";
import DownloadButton from "../../../components/DownloadButton";

export const RobeLightBoxButton = ({ href, children }: PropsWithChildren<{ href: string }>) => {
  return (
    <DownloadButton href={href} ml={{ _: 2 }} download>
      {children}
    </DownloadButton>
  );
};

const ProductPictures = () => {
  const [{ values }, { stringify, file, value }] = usePageContent<Product>();
  const [productDetail] = useContent<[ProductDetailPage]>();
  const { setShowSubheader } = useProductDetailPageStore();

  const handleLightboxClose = () => {
    setShowSubheader(true);
    document.body.classList.remove("no-scroll");
  };

  const images = useMemo(() => values.images.map((i) => file(i, "1920x1920")), [file, values.images]);
  const originalImages = useMemo(() => values.images.map((i) => file(i)), [file, values.images]);

  const [openLightbox] = useGallery({
    images,
    createButtons: (offset: number) => {
      return [
        <RobeLightBoxButton
          key="png"
          href={originalImages[offset]}
        >
          {stringify(productDetail.values.downloadOriginalImageButtonText)}
        </RobeLightBoxButton>,
        <RobeLightBoxButton
          key="jpg"
          href={images[offset]}
        >
          {stringify(productDetail.values.downloadJPGButtonText)}
        </RobeLightBoxButton>
        /*        <RobeLightBoxButton
          key="all"
          href={`${cms.apiUrl}/api/product/${id}/files/images.zip`}
        >
          {stringify(productDetail.values.downloadAllButtonText)}
        </RobeLightBoxButton> */
      ];
    },
    onClose: handleLightboxClose,
    fitToPageContent: true,
    disableScroll: true,
    hideProductSubHeader: true
  });

  return (
    <Box id={"#" + stringify(productDetail.values.anchorProductPictures)}>
      <Box
        id={stringify(productDetail.values.anchorProductPictures)}
        transform={{ _: "translateY(-6rem)", md: "translateY(-14rem)" }}
      />
      <Container
        flexDirection="column"
        py={{ _: "3rem", sm: "5rem" }}
      >
        {values.images.length > 1 && (
          <RobeCarousel
            mt={{ _: "3rem", sm: "6rem" }}
            mx={{ _: "1.7rem", md: "-1.5rem" }}
            maxWidth={{ _: "calc(100% - 3.4rem)", md: "100%" }}
            dots={false}
            bg="transparent"
            slides={values.images.map((img, i) => (
              <Box
                key={i}
                px={{ _: "0.5rem", md: "1.5rem" }}
                bg="#fff"
                width="100%"
                onClick={() => openLightbox(i)}
              >
                <Card
                  variant="outline"
                  width="100%"
                  maxWidth="100%"
                  body={(
                    <Box width="100%" cursor="pointer">
                      <AspectRatio ratio={1} width="100%">
                        <Img
                          src={file(img, "242x260")}
                          // TODO: TEMP SOLUTION FOR PRODUCT BLACK BACKGROUND IMAGES REMOVE THIS AFTER FIXED
                          srcSet={`${file(img, "242x260")} 1x`}
                          alt={stringify(values.name)}
                          maxWidth="100%"
                          maxHeight={{ _: "95%", lg: "100%" }}
                          loading="lazy"
                        />
                      </AspectRatio>
                    </Box>
                  )}
                  _body={{
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between"
                  }}
                  _hover={{
                    border: "2px solid rgba(69, 86, 99, 0.75)"
                  }}
                  transition="border 300ms"
                />
              </Box>
            ))}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 768 },
                items: 4
              },
              sm: {
                breakpoint: { max: 767, min: 480 },
                items: 2
              },
              xs: {
                breakpoint: { max: 479, min: 0 },
                items: 2
              }
            }}
            arrows
          />
        )}
      </Container>
    </Box>
  );
};

export default ProductPictures;
