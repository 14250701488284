import { Content } from "@appiodev/xcore-core";
import { Link, useContent, useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { Box, Button, Flex, Link as UILink, Modal, Stack, Text, useModal } from "@xcorejs/ui";
import { ErrorAnimatedIcon, SuccessAnimatedIcon } from "components/icons/AnimatedIcon";
import regions from "country-region-data";
import Checkbox from "design-system/xcore/Form/Checkbox";
import FormControl from "design-system/xcore/Form/FormControl";
import Input from "design-system/xcore/Form/Input";
import Textarea from "design-system/xcore/Form/Textarea";
import Select, { AsyncSelect, OptionType } from "design-system/xcore/Select";
import { ChangeEvent, FC, ReactNode, useState } from "react";
import { datasheetUrl } from "templates/products/ProductDetail/data";
import { useLayout } from "xcore";
import { Inquiry } from "xcore/types";

import RobeLoader from "./RobeLoader";
import { useRouter } from "next/router";
import RobeRichtext from "./RobeRichtext";

interface InquiryRequest {
  email: string;
  region: null | { value: string; label: string };
  firstName: string;
  lastName: string;
  company: string;
  position: string;
  phone: string;
  note: string;
}

const InquiryModal: FC<{ product: Content }> = ({ product }) => {
  const { stringify } = useLayout();
  const [sent, setSent] = useState<boolean | string | ReactNode>(false);
  const { data } = useXcoreQuery(cms => cms.content.single<Inquiry>("inquiry"));

  if (!data) {
    return (
      <Modal>
        <Flex justifyContent="center" width="100%">
          <RobeLoader />
        </Flex>
      </Modal>
    );
  }

  return (
    <Modal
      header={(
        <Flex flexDirection="column">
          {stringify(data.content.values.titleInq)}
        </Flex>
      )}
      _header={{ justifyContent: "center" }}
      size="lg"
      _overlay={{
        zIndex: 5
      }}
    >
      {sent === false ? <Form inquiry={data.content} product={product} setSent={setSent} /> : <FormResult state={sent} />}
    </Modal>
  );
};

export default InquiryModal;

interface FormProps {
  inquiry: Inquiry;
  product: Content;
  setSent: (v: boolean | string | ReactNode) => unknown;
}

const Form: FC<FormProps> = ({ product, inquiry, setSent }) => {
  const { locale } = useRouter();
  const { stringify, title, header, config: { apiUrl }, cms } = useLayout();
  const [close] = useModal();

  const [products, setProducts] = useState<OptionType[]>([{ label: stringify(product.title), value: product.id }]);
  const [value, setValue] = useState<InquiryRequest>({
    email: "",
    region: null,
    firstName: "",
    lastName: "",
    company: "",
    position: "",
    phone: "",
    note: stringify(inquiry.values.notePlaceholder) ?? ""
  });
  const [checkbox, setCheckbox] = useState(false);
  const [sending, setSending] = useState(false);

  const handler = <T extends keyof InquiryRequest>(name: T) => ({
    value: value[name],
    onChange: (e: ChangeEvent<HTMLInputElement>) => setValue({ ...value, [name]: e.target.value })
  });

  return (
    <form
      onSubmit={e => {
        setSending(true);
        e.preventDefault();
        sendInquiry(apiUrl, products, value, locale)
          .then(r => setSent(r.ok ? true : (
            <Flex flexDirection="column" alignItems="center" mt="4rem">
              <ErrorAnimatedIcon />

              <Text mt="3rem">
                {stringify(inquiry.values.inquiryErrorMessage)}
              </Text>
            </Flex>
          )));
      }}
    >
      <Box mt="3rem">
        <Stack direction="column" gap="2rem">
          <Stack direction={{ _: "column", sm: "row" }}>
            <FormControl
              labelText={stringify(inquiry.values.productsInq)}
              context="isRequired"
              width="100%"
            >
              <AsyncSelect
                inputSize="md"
                width="100%"
                required
                isMulti
                value={products}
                loadOptions={async (val) =>
                  await cms.content.completion(val, ["product"])
                    .then((contents) =>
                      contents.map(c => ({ label: title(c), value: c.id }))
                    )}
                onChange={v => v ? Array.isArray(v) ? setProducts(v) : setProducts([v as any]) : setProducts([])}
              />
            </FormControl>
          </Stack>

          <Stack direction={{ _: "column", sm: "row" }} gap="2rem">
            <FormControl
              labelText={stringify(inquiry.values.firstNameInq)}
              context="isRequired"
              width="100%"
            >
              <Input inputSize="md" width="100%" required {...handler("firstName")} />
            </FormControl>

            <FormControl
              labelText={stringify(inquiry.values.lastNameInq)}
              context="isRequired"
              width="100%"
            >
              <Input inputSize="md" width="100%" required {...handler("lastName")} />
            </FormControl>
          </Stack>

          <Stack direction={{ _: "column", sm: "row" }} gap="2rem">
            <FormControl
              labelText={stringify(inquiry.values.mailInq)}
              context="isRequired"
              width="100%"
            >
              <Input inputSize="md" width="100%" required type="email" {...handler("email")} />
            </FormControl>

            <FormControl
              labelText={stringify(inquiry.values.regionSelectInq)}
              context="isRequired"
              width="100%"
            >
              <Select
                name="language"
                label="message"
                options={options}
                isMulti={false}
                value={value.region}
                onChange={v => setValue(val => ({ ...val, region: v as any }))}
              />
            </FormControl>
          </Stack>

          <Stack direction={{ _: "column", sm: "row" }} gap="2rem">
            <FormControl
              labelText={stringify(inquiry.values.companyInq)}
              width="100%"
            >
              <Input inputSize="md" width="100%" {...handler("company")} />
            </FormControl>

            <FormControl
              labelText={stringify(inquiry.values.jobPos)}
              width="100%"
            >
              <Input inputSize="md" width="100%" {...handler("position")} />
            </FormControl>
          </Stack>

          <Stack direction={{ _: "column", sm: "row" }} gap="2rem">
            <FormControl
              labelText={stringify(inquiry.values.phoneNumber)}
              context="isRequired"
              width={{ _: "100%", md: "30.5rem" }}
            >
              <Input inputSize="md" width="100%" required {...handler("phone")} />
            </FormControl>
          </Stack>

          <Stack direction={{ _: "column", sm: "row" }} gap="2rem">
            <FormControl
              labelText={stringify(inquiry.values.note)}
              width="100%"
            >
              <Textarea
                inputSize="md"
                width="100%"
                placeholder={stringify(inquiry.values.notePlaceholder)}
                minHeight={{ _: "16rem", md: "8rem" }}
                {...handler("note")}
              />
            </FormControl>
          </Stack>

          <Checkbox size="md" checked={checkbox} onChange={() => setCheckbox(v => !v)}>
            <RobeRichtext value={inquiry.values.checkboxInq} />
          </Checkbox>

          <Flex flexDirection={{ _: "column", sm: "row" }} justifyContent="space-between" alignItems="center">
            <Button disabled={!checkbox || !products.length || sending} whiteSpace="nowrap">
              {stringify(inquiry.values.sendInq)}
            </Button>

            <Stack gap="6rem" mt={{ _: "3rem", sm: 0 }} ml="2rem">
              <Text variant="strong" fontWeight={600} whiteSpace="nowrap">
                {header.nav.offices.values.officeList?.[0].offices?.[0] && (
                  <Link content={header.nav.offices.values.officeList?.[0].offices?.[0]} onClick={close}>
                    {stringify(inquiry.values.contactBtn)}
                  </Link>
                )}
              </Text>
              <Text variant="strong" fontWeight={600} whiteSpace="nowrap">
                <UILink href={datasheetUrl(product.id, locale)} target="_blank">
                  {stringify(inquiry.values.downloadBtn)}
                </UILink>
              </Text>
            </Stack>
          </Flex>
        </Stack>
      </Box>
    </form>
  );
};

const options = regions.map(r => ({ label: r.countryName, value: r.countryShortCode }));

const FormResult: FC<{state: boolean | string | ReactNode}> = ({ state }) => {
  const { stringify } = useLayout();
  const [,modals] = useContent<[any, any, Inquiry]>();

  return (
    <>
      {state === true ? (
        <Flex flexDirection="column" alignItems="center" mt="4rem">
          <SuccessAnimatedIcon />

          <Text mt="3rem">
            {stringify(modals.values.inquirySuccessMessage)}
          </Text>
        </Flex>
      ) : state}
    </>
  );
};

const sendInquiry = (apiUrl: string, products: OptionType[], { ...value }: InquiryRequest, locale: string) =>
  fetch(`${apiUrl}/api/inquiry?locale=${locale}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      ...value,
      region: value.region?.value,
      contentIds: products.map(p => p.value)
    })
  });
